* {
    margin: 0;
    padding: 0;
}

html,
body {
    width: 100%;
    overflow-x: hidden;
}

body {
    font-size: 16px;
    font-family: $fontFamily;
    display: flex;
    min-height: 100vh;
    flex-direction: column;   
    color: $grey;
    &.is-fixed {
        overflow: hidden;
    }
}


.text-center {
    text-align: center;
}

.uppercase {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.backgrounded {
    @extend %backgrounded;
}

#body {
    padding-top: 2em;
    padding-bottom: 2em;
}
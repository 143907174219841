/* BASKET AND ORDER */
#orderedProducts table {
    width: 100%;
    margin: 0;
    font-size: 1em;
    @include respondTo(screensmmax){
        font-size: 0.8rem;
    }
}

#orderedProducts td,
#orderedProducts th {
    padding: 6px 7px;
}

#orderedProducts thead .price,
#orderedProducts thead .quantity,
#orderedProducts thead .summary {
    text-align: right;
}

#orderedProducts thead td {
    padding-bottom: 11px;
}

#orderedProducts thead em {
    padding: 0 4px 0 0;
    font-style: normal;
}

#orderedProducts tbody th {
    text-align: left;
}


#orderedProducts tbody th a {
    background: inherit;
}

#orderedProducts tbody .price,
#orderedProducts tbody .summary {
    font-weight: bold;
    text-align: right;
}

#orderedProducts tbody .quantity,
#orderedProducts tbody .quantity input {
    text-align: right;
}

#orderedProducts tbody .quantity label {
    display: none;
}

#orderedProducts tfoot {
    text-align: right;
}

#orderedProducts tfoot .summaryProducts,
#orderedProducts tfoot .summaryProducts th,
#orderedProducts tfoot .summaryShippingPayment,
#orderedProducts tfoot .summaryOrder {
    text-align: right;
}

#basket #orderedProducts table {
    margin: 10px 0 0;
}


#basket form{
    @include respondTo(screensmmax){
        overflow-x: scroll;
    }
}

#basket .info {
    padding: 10px 0;
    text-align: left;
}

#basket #recount .submit {
    padding: 2px 3px;   
}

#basket #save {
    text-align: left;
}

#basket .summaryProducts {
    font-size: 1.36em;
    font-weight: bold;
}

#basket .summaryProducts #summary {
    font-weight: bold;
}

#basket .del {
    padding: 0 5px 0 10px;
}

#basket .buttons #save .submit {
    padding: 3px 5px;
}

#basket .buttons .submit.continue {
    margin-right: 5px;
}

#basket #pageDescription {
    margin-top: 20px;
}

#basket legend {
    display: none;
}

#basket #continue {
    text-align: left;
}

#basket #save {
    padding: 6px 7px 0;
}

#order legend {
    display: block;
    padding: 20px 0 10px;
    border: 0;
    font-size: 1.2em;
    font-weight: bold;
}

#order #personalData {
    padding: 12px 3%;
    overflow: hidden;
}

#order #personalData #setBasic,
#order #personalData #setExtend {
    @include respondTo(screenmdmin){
        float: left;
        width: 50%;        
    }
}

#order #personalData label {
    display: block;
    padding: 2px 0;
    font-weight: bold;
}

#order #personalData .input,
#order #personalData #oComment {
    width: 90%;
    margin: 0 0 5px;
}

#order #personalData #oComment {
    height: 125px;
}

#shippingAndPayments div {
    padding: 11px 3%;
}

#order .summaryProducts,
#order .summaryShippingPayment,
#orderPrint .summaryShippingPayment {
    font-size: 1.182em;
}

#order .summaryProducts th,
#order .summaryShippingPayment th,
#orderPrint .summaryShippingPayment th {
    font-weight: normal;
    text-align: right;
}

#order .summaryOrder,
#orderPrint .summaryOrder {
    font-size: 1.36em;
    font-weight: bold;
}

#order .summaryOrder th,
#orderPrint .summaryOrder th {
    text-align: right;
}

#order #rulesAccept th,
#order #nextStep th {
    text-align: right;
    font-weight: normal;
}

#order #rulesAccept th a {
    font-weight: bold;
}

*:first-child+html .nextStep .submit,
*:first-child+html #basket #save .submit {
    padding: 2px 0px;
}

/* IE 7 hack */

/* ORDER SUMMARY/PRINT PAGE */
#orderPrint #pageDescription {
    padding: 0;
}

#orderPrint #pageDescription p {
    padding: 5px 0;
    font-weight: normal;
    font-size: 1.5em;
    text-align: center;
}

#orderPrint .legend {
    clear: both;
    padding: 20px 0 10px;
    font-size: 1.2em;
    font-weight: bold;
}

#orderPrint dl {
    float: left;
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0;
}

#orderPrint dt,
#orderPrint dd {
    float: left;
    display: inline-block;
    width: 40%;
    margin: 0;
    padding: 3px 0;
}

#orderPrint dt {
    clear: left;
    text-align: right;
}

#orderPrint dd {
    padding-left: 3%;
    font-weight: bold;
}

#orderPrint dd.orderComment,
#orderPrint dd.orderIP {
    font-weight: normal;
}
/*!
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

@import "../../node_modules/bootstrap/scss/_functions";
@import "helpers/_variables";
@import "../../node_modules/bootstrap/scss/_variables";

@import "../../node_modules/bootstrap/scss/mixins/_breakpoints";
@import "../../node_modules/bootstrap/scss/mixins/_grid-framework";
@import "../../node_modules/bootstrap/scss/mixins/_grid";

@import "../../node_modules/bootstrap/scss/_grid";
@import "../../node_modules/bootstrap/scss/utilities/_display";
@import "../../node_modules/bootstrap/scss/utilities/_flex";
@import "../../node_modules/bootstrap/scss/utilities/_spacing";


@import "helpers/_core";
@import "helpers/_extends";
@import "helpers/_fonts";
@import "helpers/_mixins";
@import "layouts/_body";
@import "layouts/_header";
@import "layouts/_footer";
@import "layouts/_modules";
@import "components/_colors";
@import "components/_typography";
@import "components/_products_list";
@import "components/_product_details";
@import "components/_menu";
@import "components/_page";
@import "components/_order";
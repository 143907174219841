.sidebar,
.mobile-menu {
    .main-menu {
        ul {
            padding: 10px 0;
            list-style: none;

            a {
                padding: 10px;
                display: block;
                width: 100%;
                font-weight: bold;
                text-decoration: none;

                &:hover {
                    background-color: $light-grey;
                }
            }

            li.selected>a {
                background-color: $light-grey;
            }

        }

        .sub1 {
            a {
                font-weight: 400;
                font-size: 0.9em;
                padding-left: 20px;
            }
        }

    }
}

.sidebar {
    box-shadow: 0 6px 15px 0 rgba(0, 0, 0, .1);
    margin-right: 20px;
}

.navigation {
    display: flex;

    ul {
        @extend .background-main;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        li {
            display: flex;
            position: relative;

            a {
                display: flex;
                padding: 20px;
                font-weight: bold;
                color: white;
                text-decoration: none;
                @include transitions;

                &:hover {
                    background-color: transparentize(white, 0.85);
                }
            }

            &.selected a {
                background-color: transparentize(white, 0.85);
            }
        }
    }

    .sub1 {
        position: absolute;
        top: 100%;
        z-index: 10;
        @extend .background-main;
        min-width: 100%;
        max-width: 160px;
    }
}

.close-mobile-menu {
    @include closer(20px, grey, 10px);
    @include square(20px);
    border: none;
    appearance: none;
    background-color: white;
    top: 10px;
}

.main-menu {

    .type {
        display: none;
    }

    li.has-submenu {
        &>a {
            position: relative;
            padding-right: 1.8em;

            &:after {
                position: absolute;
                top: calc(50% - 0.4em);
                right: 0.4em;
                @extend %fontello;
                content: '\f004';
                display: inline-block;
                font-size: 0.8em;
                @include transitions;
            }
        }

        &.is-open {
            &>a {
                &:after {
                    content: '\f005';
                    transform: rotate(360deg);
                }
            }

            .sub1 {
                display: block;
            }
        }
    }

    .sub1 {

        display: none;

        a {
            display: block;
            width: 100%;
            padding: 10px 10px 10px 20px;
            font-weight: normal;
        }
    }

}

.mobile-menu {
    padding-top: 40px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-height: 100%;
    display: none;
    background-color: white;

    &.is-open {
        display: block;
        z-index: 20;
    }
}
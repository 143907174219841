.color {

    &-main {
        color: var(--main-color);

        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            color: black;
        }
    }

    &-secondary {
        color: var(--secondary-color);

        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            color: grey;
        }
    }

    &-minor {
        color: var(--minor-color);

        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            color: red;
        }
    }

    &-white {
        color: white;
    }

    &-black {
        color: black;
    }

    &-light-grey {
        color: $light-grey;
    }

}

.background {

    &-main {
        background-color: var(--main-color);

        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            background-color: black;
        }
    }

    &-secondary {
        background-color: var(--secondary-color);

        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            background-color: grey;
        }
    }

    &-minor {
        background-color: var(--minor-color);

        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            background-color: red;
        }
    }

    &-white {
        background-color: white;
    }

    &-black {
        background-color: black;
    }


    &-light-grey {
        background-color: $light-grey;
    }

}

.brcolor {
    &-main {
        border-color: var(--main-color);

        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            border-color: black;
        }
    }

    &-secondary {
        border-color: var(--secondary-color);

        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            border-color: gray;
        }
    }

    &-minor {
        border-color: var(--minor-color);

        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            border-color: red;
        }
    }
}
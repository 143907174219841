.footer {
    &__navigation {
        #menu2 {
            padding: 1em 0;
            ul {
                text-align: center;

                li {
                    display: inline-block;
                    border-right: 1px solid white;

                    &:last-child {
                        border-right: 0;
                    }

                    a {
                        padding: 0 10px;
                        display: inline-block;
                        color: white;
                        text-decoration: none;
                        font-weight: normal;
                        font-size: 0.8em;
                    }
                }
            }
        }
    }
}


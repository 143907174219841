$grid-gutter-width: 10px !default;

$light-grey: #f2f3f4;
$grey: #555;


$fontFamily: 'Open Sans',
Arial,
sans-serif;

//responsive
$smartphone: 480px;
$tabletPortrait: 768px;
$tabletLandscape: 992px;
$ipadmax: 1025px;
$desktop: 1200px;
$desktopBig: 1440px;
$desktopVeryBig: 1600px;
$desktopSuperBig: 1880px;
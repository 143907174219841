.search-form {
    position: relative;
    z-index: 0;

    &__fieldset {
        position: relative;
        border: 1px solid;
        @extend .brcolor-main;
        height: 36px;
        border-radius: 20px;
        background-color: white;

        &:focus-within {
            border: 1px solid;
            @extend .brcolor-secondary;
        }


        &::after {
            @extend %fontello;
            content: '\e801';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 0;
            @extend .color-main;
            font-size: 1.2em;
        }
    }

    &__input {
        @extend %fullAbs;
        display: block;
        height: 100%;
        padding-left: 20px;
        padding-right: 60px;
        border: 0;
        background-color: transparent;
        appearance: none;
        width: 100%;
        border-radius: 20px;

        &:focus {
            outline: 0;
        }
    }

    &__submit {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        border: 0;
        width: 40px;
        border-radius: 20px;
        text-indent: -9999px;
        background-color: transparent;
        @extend %fontello;
        content: '\e801';
        z-index: 2;
    }


    label,
    legend {
        display: none;
    }

}
table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 100%;
}

tbody,
tfoot,
thead {
    margin: 0;
    padding: 0;
}

caption,
tr,
th,
td {
    margin: 0;
    padding: 2px 4px;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.input,
select,
textarea {
    padding: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: #6c6c6c #cccccc #cccccc #6c6c6c;
    font-size: 1em;
    text-shadow: 1px 1px #e0e0e0;
}

.input.warning-required,
select.warning-required,
textarea.warning-required {
    border-color: #ac0000 #c78688 #c78688 #ac0000;
    background: #fbefef;
}

.input:focus,
select:focus,
.textarea:focus {
    text-shadow: none;
}



.form legend {
    display: none;
}

.form label span {
    padding-left: 10px;
    color: #949494;
    font-weight: normal;
    text-shadow: none;
}

.hide {
    display: none;
}

a {
    @extend .color-main;
    text-decoration: underline;
}

a:hover {
    color: #666;
    text-decoration: underline;
}

.clear {
    clear: both;
    height: 0px;
    font-size: 0px;
    line-height: 0px;
}

#skiplinks {
    position: absolute;
    top: 0px;
    left: 0px;
}

#skiplinks a {
    position: absolute;
    top: -500px;
    left: 0px;
    z-index: 10;
    min-width: 200px;
    padding: 10px;
    color: white;
}

#skiplinks a:focus {
    top: 0;
    background: #000;
    color: white;
}



.order #column {
    display: none;
}













.tS {
    float: right;
    margin: 17px 0 3px;
}

.tS div {
    display: inline;
}

.tS a {
    margin: 0 4px;
    text-decoration: none;
    background: inherit;
    color: #2e6a97;
    font-weight: bold;
    font-family: Arial;
}

.tS a:hover {
    @extend .color-main;
    background: inherit;
}

.tS0 {
    font-size: 0.917em;
}

.tS1 {
    font-size: 1.083em;
}

.tS2 {
    font-size: 1.333em;
}

.pages {
    padding: 8px 0;
    text-align: right;
    font-size: 1.083em;
}

.pages ul,
.pages li {
    display: inline;
}

.pages li {
    padding: 0 2px;
}

.pages a,
.pages strong {
    padding: 0 1px;
    font-size: 1.083em;
}

/* FILES LIST STYLES */
#filesList {
    width: 100%;
    float: left;
    margin: 15px 0 5px;
    list-style: none;
    vertical-align: middle;
    border-left: 2px solid #e0e0e0;
}

#filesList li {
    width: 100%;
    float: left;
    clear: left;
    margin: 5px 0 5px 10px;
    padding-left: 10px;
}

#filesList li a {
    @extend .color-main;
    background: inherit;
}

#filesList li a:hover {
    color: #666;
    background: inherit;
}

#filesList img {
    margin: 0 8px 0 0;
    vertical-align: middle;
}

#filesList em {
    padding-left: 2px;
}

/* QUICK.BOX */
#quick-box {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 200;
}

#quick-box .background {
    height: 100%;
}

#quick-box .quick-box-container {
    position: absolute;
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 0 15px 1px #000;
}

#quick-box .image-wrapper {
    position: relative;
    line-height: 0;
}

#quick-box .image-wrapper img {
    margin: 0;
    padding: 0;
    border-radius: 5px;
}

#quick-box .navigation {
    position: absolute;
    bottom: 0;
    margin: 0;
    padding: 5px 0;
    text-align: center;
    font-size: 0.875em;
    font-weight: bold;
    color: #ddd;
    line-height: normal;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
}

#quick-box .description {
    margin: 0;
    padding: 5px 0;
    text-align: center;
    font-size: 0.875em;
    color: #ddd;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.8);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-height: 66px;
    overflow: hidden;
}

#quick-box .prev,
#quick-box .next {
    position: absolute;
    display: block;
    width: 50%;
    top: 15%;
    height: 70%;
    margin: 0px;
    color: white;
    font-family: Verdana;
    z-index: 100;
    opacity: 0.15;
    text-decoration: none;
}

#quick-box .prev span,
#quick-box .next span {
    position: absolute;
    top: 47%;
    left: 0;
    padding: 10px;
    border: 1px solid white;
    background: #000;
}

#quick-box .next span {
    left: auto;
    right: 0;
    border-right: none;
}

#quick-box .prev span {
    border-left: none;
}

#quick-box .next:hover,
#quick-box .prev:hover {
    opacity: 0.8;
}

#quick-box .prev {
    left: 0;
}

#quick-box .next {
    right: 0;
}

#quick-box .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 4px 2px;
    opacity: 0.5;
    background: #000;
    color: white;
    font-weight: bold;
    font-family: "Trebuchet MS";
    text-decoration: none;
    border: 1px solid white;
    border-top: none;
    border-right: none;
    border-top-right-radius: 5px;
    z-index: 100;
}

#quick-box .close:hover {
    opacity: 0.8;
}

#quick-box .close,
#quick-box .prev,
#quick-box .next {
    line-height: normal;
}

#quick-box .loading {
    display: block;
    padding: 40px 35px;
    color: white;
    line-height: 1em;
}

/* SUB PAGES LIST STYLES */
#content .subpagesList {
    float: left;
    width: 100%;
    margin: 7px 0;
    list-style: none;
}

#content .subpagesList li {
    clear: left;
    float: left;
    width: 95%;
    margin: 10px 0;
    padding: 18px 2.5%;
    background: white url('img/body.png');
    border-bottom: 1px solid #dadada;
    -webkit-box-shadow: 0 10px 5px -5px rgba(0, 0, 0, .15);
    box-shadow: 0 10px 5px -5px rgba(0, 0, 0, .15);
}

* html #content .subpagesList li {
    width: 100%;
}

/* IE 6 hack */
#content .subpagesList li .photo {
    float: left;
    margin: 0 20px 5px 0;
}

#content .subpagesList li h2 {
    padding-bottom: 10px;
    text-shadow: 2px 2px 3px #ddd;
}

#content .subpagesList li h2,
#content .subpagesList li h2 a {
    @extend .color-main;
    font-size: 1.25em;
    font-weight: normal;
    background: none;
}

#content .subpagesList li h2 a:hover {
    color: #666;
    background: none;
}

#content .subpagesList li div.description {
    font-weight: normal;
    font-size: 1em;
    line-height: 1.4em;
}

#content .subpagesList li ul,
#content .subpagesList li ol {
    float: left;
    width: 100%;
    margin: 5px 0 0 30px;
    padding: 0;
}

#content .subpagesList li li {
    padding: 0;
    border-bottom: 0;
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#content .subpagesList li ul {
    list-style: disc;
}

#content .subpagesList li ol {
    list-style: decimal;
}

#content .subpagesList li p {
    padding-bottom: 10px;
}

/* OPTIONS LINK */
#options {
    clear: both;
    float: right;
    width: 100%;
    margin: 10px 10px;
    color: #666;
    font-size: 1em;
    text-align: right;
}

*:first-child+html #options {
    width: 94%;
}

/* IE 7 hack */
* html #options {
    width: 94%;
}

/* IE 6 hack */
#options a {
    padding: 0 10px 0;
    color: #2e6a97;
    background: inherit;
}

#options a:hover {
    @extend .color-main;
    background: inherit;
}

#options .back,
#options .print {
    float: right;
}

#options .print a {
    border-left: 1px solid #e0e0e0;
}

.message {
    padding: 50px 0;
    text-align: center;
}

.message h2 {
    background: inherit;
    @extend .color-main;
    font-weight: normal;
    font-size: 2.4em;
    line-height: 1.7em;
}

.message h2 a {
    color: #2e6a97;
    background: inherit;
}

.message h2 a:hover {
    color: #666;
    background: inherit;
}
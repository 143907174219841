#products {
    width: 100%;
}

#products ul.list {
    width: 100%;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

#products .list li {
    padding: 20px;
    text-align: center;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;

    @include respondTo(screensmmin) {

        &:nth-child(3n+1),
        &:nth-child(3n+2) {
            border-right: 1px solid $light-grey;
        }
    }

    @include respondTo(screensm) {
        &:nth-child(odd) {
            border-right: 1px solid $light-grey;
        }
    }

    @include respondTo(screensmmax) {
        flex: 0 0 50%;
        max-width: 50%;
    }

    @include respondTo(screenxsmax) {
        flex: 0 0 100%;
        max-width: 100%;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-start;


    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;

    .product-list-price {

        flex: 1;

        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            flex: 1 0 auto;
        }

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .price {
            margin-bottom: 1em;

            em {
                padding-right: 2px;
                font-style: normal;
            }

            strong {
                @extend .color-main;
                padding-right: 2px;
                font-size: 1.1rem;
            }
        }
    }
}

#products li h2 {
    padding: 0 0 1em;
    font-weight: bold;
    font-size: 1.2em;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}


#products li .description {
    margin-bottom: 1em;
    text-align: left;

}

#products li .photo {
    margin-bottom: 1em;

    a {
        display: block;

        img {
            width: 100%;
            max-width: 100%;
            @include transitions(all, 0.3s, ease, 0s);
        }

        &:hover {
            img {
                transform: scale(1.01, 1.01);
            }
        }
    }
}




#products li .basket {
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        @extend .background-main;
        color: white;
        padding: 1em;
        font-weight: bold;
        text-decoration: none;
        @include transitions(all, 0.3s, ease, 0s);

        &:hover {
            transform: scale(1.01, 1.01);
            opacity: 0.9;
        }
    }
}

#products li .noPrice {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grey;
    color: white;
    padding: 1em;
    font-weight: bold;
    cursor: not-allowed;
}

.pages,
.sort {
    font-size: 0.8rem;

    ul {
        display: inline-block;

        li {
            list-style: none;
            display: inline-block;
            padding: 0 0.5em;
        }
    }

    a {
        display: inline-block;
        padding: 0.5em 0;
        text-decoration: none;
        font-weight: 600;
    }
}

.sort+.pages {
    display: none;
}

.pages {
    .viewAll {
        padding-right: 0.5em;
    }
}
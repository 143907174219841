.intro-title {
    font : {
        weight: bold;        
        size: 3rem;
    }
}

.txt-title {
    font : {
        weight: bold;       
        size: 2.5rem;
    }
}
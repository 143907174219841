#product {
    display: block;
    text-align: left;
}


/* IE 6 hack */
#product h1 {
    padding: 5px 0 5px;
    background: inherit;
    @extend .color-main;
    font-size: 2.25em;
    font-weight: normal;
}

#product div.breadcrumb {
    margin: 0 0 10px;
    padding: 2px 0 7px;
    border-bottom: 1px solid #bebebe;
    font-weight: normal;
    font-size: 0.917em;
}

#product div.breadcrumb,
#product div.breadcrumb a {
    background: inherit;
    color: #666;
}

#product div.breadcrumb a:hover {
    background-color: inherit;
    @extend .color-main;
}

#product div.breadcrumb em {
    padding: 0 5px;
}



#product div.content {
    clear: left;
    padding: 10px 0 10px;
    font-size: 1.083em;
    line-height: 1.5em;
}

#product div.content ul,
#product div.content ol {
    margin: 5px 0 0 30px;
    padding: 0;
}

#product div.content li {
    margin: 8px 0;
    padding: 0;
    border-left: 0;
}

#product div.content p {
    padding-bottom: 10px;
}

#product #box {
    #noPrice {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $grey;
        color: white;
        padding: 1em;
        font-weight: bold;
        cursor: not-allowed;
    }

    #price {
        margin-bottom: 1em;

        em {
            padding-right: 2px;
            font-style: normal;
        }

        strong {
            @extend .color-main;
            padding-right: 2px;
            font-size: 1.1rem;
        }
    }

    #available {
        margin-bottom: 1em;
    }

    #addBasket {
        fieldset {
            border: none;
        }

        input.submit {
            border: none;
            appearance: none;
            display: flex;
            justify-content: center;
            align-items: center;
            @extend .background-main;
            color: white;
            padding: 1em;
            font-weight: bold;
            text-decoration: none;
            @include transitions(all, 0.3s, ease, 0s);

            &:hover {
                transform: scale(1.01, 1.01);
                background-color: transparentize(white, 0.9);
            }

        }
    }
}


/* IMAGES STYLES */
#content .imagesList {
    list-style: none;
}

#content .imagesList li {
    margin: 0 0 15px 0;
    text-align: center;
}

#content .imagesList li div {
    text-align: center;
}




#imagesList1 {
    float: left;
    margin: 9px 20px 0 0;
}

#imagesList2 {
    clear: right;
    float: right;
    margin: 8px 0 0 15px;
}

#content .imagePreview div {
    margin: 5px 0 10px;
    text-align: center;
}

#imagesListPreview {
    float: left;
    margin: 9px 20px 0 0;

    @include respondTo(screenmdmax) {
        margin-right: 0;
    }
}

* html #imagesListPreview {
    margin-top: -5px;
}

#content .imagesList#imagesListPreview li {
    margin-bottom: 3px;
}

#content .imagesList#imagesListPreview li img {
    border-width: 2px;
}

#content .imagesList#imagesListPreview li img:hover {
    border-width: 2px;
}
.header-desktop {
    &__head {
        ul {
            text-align: center;
            letter-spacing: normal;
            display: block;

            li {
                display: inline-block;

                a {
                    display: inline-block;
                    padding: 0.8em;
                    text-decoration: none;
                    font-size: 0.8em;
                    font-weight: 600;
                }
            }
        }
    }

    &__body {
        padding-top: 1em;
        padding-bottom: 1em;

    }

}


.logo {
    &__title {
        font-size: 2em;

        @include respondTo(screenmdmax) {
            font-size: 1em;
        }


        a {
            display: inline-block;
            text-decoration: none;

            @include respondTo(screenmdmax) {
                text-align: center;
                color: white;
            }
        }

        i {
            display: inline-block;

            &::before {
                @extend .color-secondary;
            }

        }
    }

    &__slogan {
        @include respondTo(screenmdmax) {
            display: none;
        }
    }
}

.header-mobile {
    &__head {
        @extend .header-desktop__head;

        ul li a {
            padding: 0.8em 0.4em;
            font-size: 0.7em;
        }
    }

    &__main {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__search {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }
}

.open-mobile-menu {
    @include buttons(white, white, transparent, transparentize(white, 0.85));
    @include square(40px);
    border: 0;
    appearance: none;
    position: relative;

    span {
        display: inline-block;
        position: absolute;
        height: 0;
        left: 10px;
        right: 10px;
        border: 0.5px solid white;
        border-radius: 1px;
        background-color: white;
    }

    span {

        &:nth-child(1) {
            bottom: calc(50% + 5.5px);
        }

        &:nth-child(2) {
            top: calc(50% - 0.5px);

        }

        &:nth-child(2) {
            top: calc(50% + 5.5px);
        }

    }

}

.overlay {
    display: none;
    @extend %fullAbs;
    z-index: 18;
    background-color: transparentize(black, 0.5);

    &.is-open {
        display: block;
    }
}


.mini-basket-logo ul {

    list-style: none;
    display: flex;
    justify-content: flex-end;

    li.lBasket {
        height: 34px;
        display: inline-block;
        @extend .background-secondary;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;

        @include respondTo(screenmdmax) {
            margin-right: 15px;
            border-radius: 100%;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            position: relative;
            padding-left: 10px;
            padding-right: 10px;
            color: white;
            text-decoration: none;

            @include respondTo(screenmdmax) {
                font-size: 0;
                padding: 0;
                @include square(34px);
            }

            &:before {
                @extend %fontello;
                content: '\e800';

                @include respondTo(screenmdmax) {
                    font-size: 16px;
                }
            }
        }

        span {
            position: absolute;
            right: -6px;
            top: -6px;
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 100%;           
            @extend .background-minor;
            overflow: hidden;
            text-indent: -99999px;

            strong {
                @extend %fullAbs;
                display: flex;
                align-items: center;
                justify-content: center;
                text-indent: 0;
                color: white;
                font-weight: 500;
                font-size: 0.8em;
            }

        }
    }
}